import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Card = styled.div`
  background: #f5f5f5;
  height: 100%;
  width: 100%;
`
const Shadow = styled.div`
  background: linear-gradient(#ccc, #ccc);
  height: 100%;
  width: 100%;
  transform: translate3d(0, 0, -10px);
  position: absolute;
  top: 0;
  left: 0;
`

const CardWrapper = styled.div`
  height: 80px;
  position: relative;
  > * {
    border-radius: 8px;
    transition: 0.2s ease-in;
  }
  &:hover ${Shadow} {
    transform: translate3d(0, -10px, -10px);
    background: linear-gradient(#ddd, #ddd);
    box-shadow: 0 0 20px 10px #ddd;
  }
  &:nth-of-type(4n + 1) {
    ${Card} {
      background: #444;
    }
  }

  &:hover ${Card} {
    background: #fff;

    transform: translate3d(0, 0, 20px) rotateX(-15deg);
    transform-origin: center bottom;
  }
`
const Combo = () => (
  <CardWrapper>
    <Card />
    <Shadow />
  </CardWrapper>
)
const TransformExp = ({ location }) => {
  const cards = React.useMemo(() => new Array(100).fill(), [])
  return (
    <Layout location={location}>
      <SEO title="3D Transform Lab" />

      <div
        style={{
          display: "grid",
          gridGap: 20,
          margin: "20px 0",
          gridTemplateColumns: "repeat(10, 1fr)",
          gridTemplateRows: "repeat(10, 1fr)",
          backfaceVisibility: "hidden",
          transformStyle: "preserve-3d",
          transform: "rotateX(60deg) rotateY(0) rotateZ(-45deg)",
        }}
      >
        {cards.map((card, cardIdx) => (
          <Combo key={cardIdx} />
        ))}
      </div>
    </Layout>
  )
}

export default TransformExp
